<template>
  <div>
    <div @click="dialogTableVisible=true"><slot></slot></div>
    <el-dialog title="选择代理区域" destroy-on-close :visible.sync="dialogTableVisible">
      <YChooseAreaPro :mode="mode" @change="change"></YChooseAreaPro>
    </el-dialog>
  </div>
</template>

<script>
import YChooseAreaPro from "@/components/shop/YChooseAreaPro/YChooseAreaPro";

export default {
  name: "YChooseAgentArea",
  components: {YChooseAreaPro},
  model: {
    prop: "modelVal",
    event: 'change'
  },
  props: {
    modelVal: {
      type: String,
      default: ""
    },
    mode: {
      type: Number,
      default: 3,
    }
  },
  watch: {
    modelVal() {
      this.value = this.modelVal;
    }
  },
  data() {
    return {
      dialogTableVisible:false,
    }
  },
  methods: {
    change(e) {
      this.dialogTableVisible =false;
      this.$emit("change", e.pop()?.value)
    },
  },
}
</script>

<style scoped>

</style>