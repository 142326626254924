<template>
  <div>{{ show }}</div>
</template>

<script>
import areaData from "@/components/shop/YChooseAreaPro/area-data";

export default {
  name: "YFormatAgentArea",
  props: {
    value: {
      type: String,
      default: "",
    }
  },
  computed: {
    levelGuess(){
      if (this.value.length === 9)return 1;
      if (this.value.substring(0, 2) + "0000" === this.value) return 4;
      if (this.value.substring(0, 4) + "00" === this.value) return 3;
      if (this.value.substring(0, 6) === this.value) return 2;
      return 0;
    },
    show() {
      if (!this.value || this.levelGuess === 0)return "";
      let pC = "";
      let pL = "";
      let cC = "";
      let cL = "";
      let dC = "";
      let dL = "";
      let sC = "";
      let sL = "";
      pC = this.value.substring(0, 2) + "0000";
      pL = areaData?.["86"]?.[pC];
      if (this.levelGuess < 4){
        cC = this.value.substring(0, 4) + "00";
        cL = areaData?.[pC]?.[cC]
      }
      if (this.levelGuess < 3){
        dC = this.value.substring(0, 6);
        dL = areaData?.[cC]?.[dC]
      }
      if (this.levelGuess < 2){
        sC = this.value;
        sL = areaData?.[dC]?.[sC];
      }
      return pL + cL + dL + sL;
    }
  },
}
</script>

<style scoped>

</style>